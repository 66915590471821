<div class="row">

  <div class="col-md-8 offset-md-2 mt-2">
      <div class="row">
          <div class="col-lg-12 content-box-centered">
              <h2 class="titulo">{{ 'ABOUT.Titulo' | translate }}</h2>
              <p class="parrafo">{{ 'ABOUT.Parrafo1' | translate }}</p>
              <p class="parrafo">{{ 'ABOUT.Parrafo2' | translate }}</p>
              <p class="parrafo">{{ 'ABOUT.Parrafo3' | translate }}</p>
              <p class="parrafo">{{ 'ABOUT.Parrafo4' | translate }}</p>

          </div>
      </div>
  </div>

</div>
<div class="row align-items-end mt-lg-5">
  <div style="padding-left: 20px;">
      <hr>
      <p class="parrafo">© LACNIC - {{ 'HOME.Copyright' | translate }}</p>
      <a  target="_blank" href="{{'HOME.LinkPoliticaPrivacidad' | translate}}">{{ 'HOME.TextoPoliticaPrivacidad' | translate }} </a>
  </div>
</div>

