<div class="row">
  <div class="col-md-12">
    <p class="widget-title text-center">IP: {{IP}}</p>
  </div>
  <div class="col-md-6 offset-md-3" *ngIf="loading">
    <img class="imgLoading" src="assets/img/loading.gif" alt="loading...">
  </div>

  <div class="col-md-6">
    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}" *ngIf="!loading && datosIP.length > 0">
      <app-collapsible-container-component
        collapseName="IP"
        [isWidget]="true"
        titleText='{{ "RESULTADOSIP.TablaIP.Titulo" | translate }}'
      >
        <table class="table table-xs">
          <tbody>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.Handle.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[0] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.Status.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[1] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.NetRange.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[2] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.NetVersion.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[3] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.Registration.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[4] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.LastChanged.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[5] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.LegalRepresentative.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[6] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.ParentHandle.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[7] }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaIP.Filas.Estado.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ datosIP[8] }}</td>
          </tr>
          </tbody>
        </table>
      </app-collapsible-container-component>
    </div>

    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}" *ngIf="!loading && datosRemarks.length > 0">
      <app-collapsible-container-component
        collapseName="Remarks"
        [isWidget]="true"
        titleText='{{ "RESULTADOSAUTNUM.TablaRemarks.Titulo" | translate }}'
      >
        <table class="table table-xs">
          <tbody>
          <tr *ngFor="let remark of datosRemarks">
            <td class="widget-table-title-cell">{{ remark.Title }}</td>
            <td class="widget-table-text-cell">{{ remark.Desc }}</td>
          </tr>
          </tbody>
        </table>
      </app-collapsible-container-component>
    </div>

    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}" *ngIf="!loading && datosReverse.length > 0">
      <app-collapsible-container-component
        collapseName="ReverseDelegations"
        [isWidget]="true"
        titleText='{{ "RESULTADOSIP.TablaReverseDelegations.Titulo" | translate }}'
      >
        <table class="table table-xs"  *ngFor="let reverse of datosReverse">
          <tbody>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaReverseDelegations.Filas.StartAddress.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ reverse.StartAddress }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaReverseDelegations.Filas.EndAddress.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ reverse.EndAddress }}</td>
          </tr>
          <tr>
            <td colspan="2" class="widget-table-subtitle">{{ "RESULTADOSIP.TablaReverseDelegations.SubtituloNameSever" | translate }}</td>
          </tr>
          <tr *ngFor="let nameserver of reverse.Nameservers">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaReverseDelegations.Filas.LdhName.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ nameserver.LdhName }}</td>
          </tr>
          <tr>
            <td colspan="2" class="widget-table-subtitle">{{ "RESULTADOSIP.TablaReverseDelegations.SubTituloSecDns" | translate }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaReverseDelegations.Filas.DelegationSigned.Titulo" | translate  }}</td>
            <td class="widget-table-text-cell">{{ reverse.SecureDNSDelSig }}</td>
          </tr>
          <tr>
            <td colspan="2" class="widget-table-subtitle" *ngIf="reverse.SecureDNSData.length > 0">{{ "RESULTADOSIP.TablaReverseDelegations.SubTituloDsData" | translate }}</td>
          </tr>
          <tr *ngFor="let dsData of reverse.SecureDNSData">
            <td colspan="2" style="border-top: 0px; padding: 0px;">
              <table style="width: 100%;">
                <tr>
                  <td class="widget-table-title-cell" style="width: 42%;" >{{ "RESULTADOSIP.TablaReverseDelegations.Filas.Zone.Titulo" | translate }}</td>
                  <td class="widget-table-text-cell">{{ dsData.zone }}</td>
                </tr>
                <tr>
                  <td class="widget-table-title-cell" >{{ "RESULTADOSIP.TablaReverseDelegations.Filas.KeyTag.Titulo" | translate }}</td>
                  <td class="widget-table-text-cell">{{ dsData.keyTag }}</td>
                </tr>
                <tr>
                  <td class="widget-table-title-cell" >{{ "RESULTADOSIP.TablaReverseDelegations.Filas.Algorithm.Titulo" | translate }}</td>
                  <td class="widget-table-text-cell">{{ dsData.algorithm }}</td>
                </tr>
                <tr>
                  <td class="widget-table-title-cell" >{{ "RESULTADOSIP.TablaReverseDelegations.Filas.Digest.Titulo" | translate }}</td>
                  <td class="widget-table-text-cell" style="word-break: break-all;">{{ dsData.digest }}</td>
                </tr>
                <tr>
                  <td class="widget-table-title-cell" >{{ "RESULTADOSIP.TablaReverseDelegations.Filas.DigestType.Titulo" | translate }}</td>
                  <td class="widget-table-text-cell" >{{ dsData.digestType }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </app-collapsible-container-component>
    </div>   

    <!-- Datos extra -->
    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}"  *ngIf="!loading && datosExtra.length > 0">
      <app-collapsible-container-component
        collapseName="Extra"
        [isWidget]="true"
        titleText='{{ "RESULTADOSIP.TablaExtra.Titulo" | translate }}'
      >
        <table class="table table-xs">
          <tbody>
          <tr *ngFor="let extra of datosExtra">
            <td class="widget-table-title-cell">{{ extra.Title }}</td>
            <td class="widget-table-text-cell">{{ extra.Desc }}</td>
          </tr>
          <tr>
            <td colspan="2" class="widget-table-subtitle">{{ "RESULTADOSIP.TablaExtra.SubTituloEventos" | translate }}</td>
          </tr>
          <tr *ngFor="let event of datosEvents">
            <td class="widget-table-title-cell">{{ event.Title }}</td>
            <td class="widget-table-text-cell">{{ event.Desc }}</td>
          </tr>
          <tr>
            <td colspan="2" class="widget-table-subtitle">{{ "RESULTADOSIP.TablaExtra.SubTituloLinks" | translate }}</td>
          </tr>
          <tr *ngFor="let link of datosLinks">
            <td class="widget-table-title-cell">{{ link.Title }}</td>
            <td class="widget-table-text-cell" *ngIf="link.Link != '#'">
              <a class="link break-all" target="_blank" href="{{link.Link}}">{{ link.Desc }}</a>
            </td>
            <td class="widget-table-text-cell" *ngIf="link.Link == '#'">{{ link.Desc }}</td>
          </tr>
          </tbody>
        </table>
      </app-collapsible-container-component>
    </div>

    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}" *ngIf="!loading && datosNotices.length > 0">
      <app-collapsible-container-component
        collapseName="Notices"
        [isWidget]="true"
        titleText='{{ "RESULTADOSAUTNUM.TablaNotices.Titulo" | translate }}'
      >
        <table class="table table-xs">
          <tbody>
          <tr *ngFor="let notice of datosNotices">
            <td class="widget-table-title-cell">{{ notice.Title }}</td>
            <td class="widget-table-text-cell" *ngIf="notice.Link != '#'">
              <a class="link" target="_blank" href="{{notice.Link}}">{{ notice.Desc }}</a>
            </td>
            <td class="widget-table-text-cell" *ngIf="notice.Link == '#'">{{ notice.Desc }}</td>
          </tr>
          </tbody>
        </table>
      </app-collapsible-container-component>
    </div>
  </div>

  <div class="col-md-6">
    <div [ngClass]="{'widget-section-container': this.widgetSectionHasRoundedBorder}" *ngIf="!loading && datosEntities.length > 0">
      <app-collapsible-container-component
        collapseName="Contacts"
        [isWidget]="true"
        titleText='{{ "RESULTADOSIP.TablaContacts.Titulo" | translate }}'
      >
        <table class="table table-xs" *ngFor="let entity of datosEntities">
          <tbody>
          <tr>
            <td colspan="2" class="widget-table-subtitle">{{ entity.Roles }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Handle.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Handle }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Name.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Name }}</td>
          </tr>
          <!--<tr>-->
          <!--<td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Address.Titulo" | translate }}</td>-->
          <!--<td class="widget-table-text-cell">{{ entity.Address }}</td>-->
          <!--</tr>-->
          <tr *ngIf="!loading && entity.MuestroAddress == '1'">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Country.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Country }}</td>
          </tr>
          <tr *ngIf="!loading && entity.MuestroAddress == '1'">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.City.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.City }}</td>
          </tr>
          <tr *ngIf="!loading && entity.MuestroAddress == '1'">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Address.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Address }}</td>
          </tr>
          <tr *ngIf="!loading && entity.MuestroAddress == '1'">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.PostalCode.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.PostalCode }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Telephone.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.TelType }} {{ entity.Telephone }}</td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Email.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Email }}</td>
          </tr>
          <tr >
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Version.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.Version }}</td>
          </tr>
          <tr >
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.Registration.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.RegistrationDate }}</td>
          </tr>
          <tr >
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.LastChanged.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">{{ entity.LastChangedDate }}</td>
          </tr>
          <tr *ngIf="mostarDatosExta">
            <td class="widget-table-title-cell">{{ "RESULTADOSIP.TablaContacts.Filas.MoreInfo.Titulo" | translate }}</td>
            <td class="widget-table-text-cell">
              <a class="link" target="_blank" [href]="sanitize(entity.Info)">Click here..</a>
            </td>
          </tr>
          <tr>
            <td class="widget-table-title-cell">Source</td>
            <td class="widget-table-text-cell">
              <a class="link" target="_blank" href="{{entity.Link}}">Click here..</a>
            </td>
          </tr>
          </tbody>
        </table>
      </app-collapsible-container-component>
    </div>
  </div>

  <div class="col-md-12 text-center">
    <span class='widget-table-text-cell'>{{ "RESULTADOSIP.Widget.Info" | translate }}<a target="_blank" href="{{linkRdap}}">RDAP-WEB</a></span>
  </div>

  <div *ngIf="mensajes.Errores.length > 0" class="alert alert-danger col-md-8 offset-md-2 errores">
    <div *ngFor="let error of mensajes.Errores;">
      {{error.Description}}
    </div>
  </div>
  <div *ngIf="mensajes.Exitos.length > 0" class="alert alert-success  col-md-8 offset-md-2 exitos">
    <div *ngFor="let exito of mensajes.Exitos;">
      {{exito.Description}}
    </div>
  </div>
</div>
