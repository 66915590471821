import { Injectable } from '@angular/core';
import { EmbedPageIframeCodeComponent } from '../components/embed-page-iframe-code/embed-page-iframe-code.component';

@Injectable()

export class EmbedPageIframeCodeService {

  /* private embedPageIframeCodeComponent: EmbedPageIframeCodeComponent = null;

  public Initialize(embedPageIframeCodeComponent: EmbedPageIframeCodeComponent): void {
    this.embedPageIframeCodeComponent = embedPageIframeCodeComponent;
  }

  public Open(): void {
    this.loading.open();
  }

  public Close(): void {
    this.loading.close();
  }

  public IsOpened(): boolean {
    return (this.loading && this.loading.isOpened()) || false;
  } */
}
