<div class="row">
  <div class="col-md-12">
      <p class="titulo text-center">ENTITY: {{ entity }}</p>
  </div>
  <div class="col-md-6 offset-md-3" *ngIf="loading">
      <img class="imgLoading" src="assets/img/loading.gif" alt="loading...">
  </div>

  <div 
    [ngClass]="{'col-md-6' : true, 'div-center': (!existenNetworks && !existenAutnums) }"
  >
    <app-collapsible-container-component
      *ngIf="!loading && datosEntity.length > 0"
      collapseName="Entity"
      [isWidget]="false"
      titleText='{{ "RESULTADOSENTITY.TablaEntity.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Handle.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Handle }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Name.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Name }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Country.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Country }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.City.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].City }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Address.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Address }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.PostalCode.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].PostalCode }}</td>
        </tr>
        <!--<tr>-->
        <!--<td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Roles.Titulo" | translate }}</td>-->
        <!--<td class="celdaTexto">{{ datosEntity[0].Roles }}</td>-->
        <!--</tr>-->
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Email.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Email }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Telephone.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].TelType }} {{ datosEntity[0].Telephone }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Registration.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Registration }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.LastChanged.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].LastChanged }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.Version.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].Version }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSENTITY.TablaEntity.Filas.LegalRepresentative.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ datosEntity[0].LegalRep }}</td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>

    <app-collapsible-container-component
      *ngIf="!loading && datosEntities.length > 0"
      collapseName="Contacts"
      [isWidget]="false"
      titleText='{{ "RESULTADOSIP.TablaContacts.Titulo" | translate }}'
    >
      <table class="table" *ngFor="let entity of datosEntities">
        <tbody>
        <tr>
          <td colspan="2" class="subtituloTabla">{{ entity.Roles }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Handle.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.Handle }}</td>
        </tr>
        <tr>
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Name.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.Name }}</td>
        </tr>
        <!--<tr>-->
        <!--<td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Address.Titulo" | translate }}</td>-->
        <!--<td class="celdaTexto">{{ entity.Address }}</td>-->
        <!--</tr>-->
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Telephone.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.TelType }} {{ entity.Telephone }}</td>
        </tr>
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Email.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.Email }}</td>
        </tr>
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Version.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.Version }}</td>
        </tr>
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.Registration.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.RegistrationDate }}</td>
        </tr>
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.LastChanged.Titulo" | translate }}</td>
          <td class="celdaTexto">{{ entity.LastChangedDate }}</td>
        </tr>
        <tr *ngIf="mostarDatosExta">
          <td class="celdaTitulo">{{ "RESULTADOSIP.TablaContacts.Filas.MoreInfo.Titulo" | translate }}</td>
          <td class="celdaTexto">
            <a class="link" target="_blank" [href]="sanitize(entity.Info)">Click here..</a>
          </td>
        </tr>
        <tr>
          <td class="celdaTitulo">Source</td>
          <td class="celdaTexto">
            <a class="link" target="_blank" href="{{entity.Link}}">Click here..</a>
          </td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>

    <!-- Datos extra -->
    <app-collapsible-container-component
      *ngIf="!loading && existenExtras"
      collapseName="Extra"
      [isWidget]="false"
      titleText='{{ "RESULTADOSIP.TablaExtra.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr *ngFor="let extra of datosExtra">
          <td class="celdaTitulo">{{ extra.Title }}</td>
          <td class="celdaTexto">{{ extra.Desc }}</td>
        </tr>
        <tr>
          <td colspan="2" class="subtituloTabla" *ngIf="datosVCard.length > 0">{{ "RESULTADOSIP.TablaExtra.SubTituloVCard" | translate }}</td>
        </tr>
        <tr *ngFor="let vcard of datosVCard">
          <td class="celdaTitulo">{{ vcard.Title }}</td>
          <td class="celdaTexto">{{ vcard.Desc }}</td>
        </tr>
        <tr>
          <td colspan="2" class="subtituloTabla">{{ "RESULTADOSIP.TablaExtra.SubTituloEventos" | translate }}</td>
        </tr>

        <tr *ngFor="let event of datosEvents">
          <td class="celdaTitulo">{{ event.Title }}</td>
          <td class="celdaTexto">{{ event.Desc }}</td>
        </tr>
        <tr>
          <td colspan="2" class="subtituloTabla">{{ "RESULTADOSIP.TablaExtra.SubTituloLinks" | translate }}</td>
        </tr>
        <tr *ngFor="let link of datosLinks">
          <td class="celdaTitulo">{{ link.Title }}</td>
          <td class="celdaTexto" *ngIf="link.Link != '#'">
            <a class="link" target="_blank" href="{{link.Link}}">{{ link.Desc }}</a>
          </td>
          <td class="celdaTexto" *ngIf="link.Link == '#'">{{ link.Desc }}</td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>

    <app-collapsible-container-component
      *ngIf="!loading && datosRemarks.length > 0"
      collapseName="Remarks"
      [isWidget]="false"
      titleText='{{ "RESULTADOSAUTNUM.TablaRemarks.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr *ngFor="let remark of datosRemarks">
          <td class="celdaTitulo">{{ remark.Title }}</td>
          <td class="celdaTexto">{{ remark.Desc }}</td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>

    <app-collapsible-container-component
      *ngIf="!loading && datosNotices.length > 0"
      collapseName="Notices"
      [isWidget]="false"
      titleText='{{ "RESULTADOSAUTNUM.TablaNotices.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr *ngFor="let notice of datosNotices">
          <td class="celdaTitulo">{{ notice.Title }}</td>
          <td class="celdaTexto" *ngIf="notice.Link != '#'">
            <a class="link" target="_blank" href="{{notice.Link}}">{{ notice.Desc }}</a>
          </td>
          <td class="celdaTexto" *ngIf="notice.Link == '#'">{{ notice.Desc }}</td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>
  </div>

  <div class="col-md-6" *ngIf="!loading && (existenNetworks || existenAutnums)">
    <app-collapsible-container-component
      *ngIf="!loading && existenNetworks"
      collapseName="Inetnums"
      [isWidget]="false"
      titleText='{{ "RESULTADOSENTITY.TablaEntity.Filas.Inetnums.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr *ngFor="let net of datosEntity[0].Networks">
          <td class="celdaTitulo">
            <span>IP network</span>

          </td>
          <td class="celdaTexto">
            <span> {{net.substring(32,net.length)}}</span>
          </td>
          <td class="celdaTexto">

            <a target="_blank" href="{{rederictUrl + 'ip/'+ net.substring(32,net.length) }}">
              <small>More info</small>
            </a><br>
            <a target="_blank" href="{{net}}">
              <small>Source</small>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>

    <app-collapsible-container-component
      *ngIf="!loading && existenAutnums"
      collapseName="Autnums"
      [isWidget]="false"
      titleText='{{ "RESULTADOSENTITY.TablaEntity.Filas.Autnums.Titulo" | translate }}'
    >
      <table class="table">
        <tbody>
        <tr *ngFor="let autnum of datosEntity[0].Autnums">
          <td class="celdaTitulo">
            <span>Autnum</span>
          <td class="celdaTexto">
            <span>{{autnum.substring(36,autnum.length)}}</span></td>
          <td class="celdaTexto">
            <a target="_blank" href="{{rederictUrl + 'autnum/'+ autnum.substring(36,autnum.length) }}">
              <small>More info</small>
            </a><br>

            <a target="_blank" href="{{autnum}}">
              <small>Source</small>
            </a></td>
        </tr>
        </tbody>
      </table>
    </app-collapsible-container-component>
  </div>

  <div *ngIf="mensajes.Errores.length > 0" class="alert alert-danger col-md-10 offset-md-1 errores">
      <div *ngFor="let error of mensajes.Errores;">
          {{error.Description}}
      </div>
  </div>
  <div *ngIf="mensajes.Informaciones.length > 0" class="alert alert-info col-md-10 offset-md-1 informaciones">
    <div *ngFor="let info of mensajes.Informaciones;">
      <div *ngIf="info.isJSON" [innerHTML]="info.Description | prettyjson"></div>
      <span *ngIf="!info.isJSON">{{info.Description}}</span>
    </div>
  </div>
  <div *ngIf="mensajes.Exitos.length > 0" class="alert alert-success  col-md-10 offset-md-1 exitos">
      <div *ngFor="let exito of mensajes.Exitos;">
          {{exito.Description}}
      </div>
  </div>
</div>

<app-embed-page-iframe-code-component [iframeSRC]="'https://rdap-web.lacnic.net/rdap/query-entity/' + this.entity"></app-embed-page-iframe-code-component>
