<div class="floatingButton" data-toggle="modal" data-target="#iframeCodeModal"(click)="this.copyButtonPressed = false;">
  <p>
    <span>{{ "COMPONENTS.EMBED.TextoBotonFlotante" | translate }}</span>
    <br>
    <i class="fa fa-code" aria-hidden="true"></i>
  </p>
</div>

<!-- Modal -->
<div class="modal fade" id="iframeCodeModal" tabindex="-1" role="dialog" aria-labelledby="iframeCodeModalTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="iframeCodeModalTitle">{{ "COMPONENTS.EMBED.ModalTitulo" | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea style="font-size: small; text-align: center; width: 100%; resize: none; outline: -webkit-focus-ring-color auto 0px !important; padding: 10px 0;" #iframeCode readonly>&lt;iframe width=&quot;560&quot; height=&quot;560&quot; src=&quot;{{iframeSRC}}&quot; frameborder=&quot;0&quot;&gt;&lt;/iframe&gt;</textarea>
        <div *ngIf="this.copyButtonPressed" class="alert alert-success" role="alert">
          {{ "COMPONENTS.EMBED.ModalAvisoTextoCopiado" | translate }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" [ngxClipboard]="iframeCode" (click)="this.copyButtonPressed = true;" style="cursor: pointer;">{{ "COMPONENTS.EMBED.ModalBotonCopiarTexto" | translate }}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" style="cursor: pointer;">{{ "COMPONENTS.EMBED.ModalBotonCerrarTexto" | translate }}</button>
      </div>
    </div>
  </div>
</div>
