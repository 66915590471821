<div class="container">
  <nav class="navbar navbar-toggleable-md navbar-inverse fixed-top menuHorizontal">
      <button class="navbar-toggler navbar-toggler-right hidden-lg-up" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
              aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
      <div class="tituloMenu">RDAP Web client</div>
      <div class="collapse navbar-collapse" id="navbarsExampleDefault">
          <!-- Menú Horizontal -->
          <ul class="navbar-nav mr-auto ">
              <li class="nav-item">
                  <a class="nav-link linkMenuBar" routerLink="/rdap/home">{{ 'MASTERPAGE.MENU.LinkHome' | translate }}</a>
              </li>
          </ul>
          <!-- Selección de Idiomas -->
          <form class="form-inline my-2 my-lg-0">
              <div [ngClass]="{ 'linkIdioma':true, 'linkIdiomaActivo':idiomaActual=='es' }" (click)="cambiarLenguaje('es')">Español</div>
              <span class="separadorLinkIdioma">|</span>
              <div [ngClass]="{ 'linkIdioma':true, 'linkIdiomaActivo':idiomaActual=='en' }" (click)="cambiarLenguaje('en')">English</div>
              <span class="separadorLinkIdioma">|</span>
              <div [ngClass]="{ 'linkIdioma':true, 'linkIdiomaActivo':idiomaActual=='pt' }" (click)="cambiarLenguaje('pt')">Português</div>
          </form>
      </div>
  </nav>

  <div class="container-fluid">
      <div class="row">
          <nav class="col-sm-3 col-md-2 hidden-xs-down bg-faded sidebar">
              <!-- Menú Vertical -->
              <ul class="nav nav-pills flex-column">
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/home" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkHome' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/consulta-por-autnum" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkAutnum' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/consulta-por-ip" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkIp' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/consulta-por-entity" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkEntity' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/consulta-por-nameserver" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkNameserver' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/consulta-por-domain" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkDomain' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/busqueda-por-entities" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkBusquedaEntities' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/busqueda-por-domains" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkBusquedaDomains' | translate }}</a></li>
                  <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/busqueda-por-nameservers" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkBusquedaNameservers' | translate }}</a></li>
                  <!-- <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/consulta-entities-por-nombre" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkEntitiesByName' | translate }}</a></li> -->
                   <li class = "nav-item"><a class="nav-link linkMenu" routerLink="/rdap/about" routerLinkActive="active">{{ 'MASTERPAGE.MENU.LinkAbout' | translate }}</a></li>
               </ul>
               <a class="navbar-brand" href="https://www.lacnic.net">
                   <img class="logoMenu" src="assets/img/LACNIC-logo-300dpi-032016.png"/>
               </a>
           </nav>
           <!-- Página -->
          <main class="col-sm-9 offset-sm-3 col-md-12 offset-md-1 pt-3">
              <div>
                  <router-outlet></router-outlet>
              </div>

          </main>
      </div>
  </div>
</div>
