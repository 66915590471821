<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Title</title>
</head>
<body>

<div class="container mt-lg-5">
    <div class=" row justify-content-center">
        <div class="col-md-10 text-center">

            <h1>Oops.. page not found</h1>
            <br>
            <a routerLink="/home"><img style="width: 20%" src="assets/img/LACNIC-logo-300dpi-032016.png"/></a>
        </div>

    </div>


</div>

</body>
</html>
